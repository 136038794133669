<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.title')"
            v-model="form.name"
            :placeholder="$t('placeholder.enter_title')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.email')"
            v-model="form.email"
            :placeholder="$t('placeholder.enter_email')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.phone')"
            v-model="form.phone"
            :placeholder="$t('placeholder.enter_phone')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.address')"
            v-model="form.address"
            :placeholder="$t('placeholder.enter_address')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppInput from '@/components/form-groups/AppInput';

export default {
  components: {
    AppInput,
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        address: null,
        email: null,
        name: null,
        phone: null,
      }
    }
  },
  beforeMount() {},
  computed: {},
  watch: {
    company(data) {
      if (data) {
        this.form.address = data.address;
        this.form.email = data.email;
        this.form.name = data.name;
        this.form.phone = data.phone;
      }
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  },
  methods: {}
}
</script>